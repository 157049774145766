import { ListItemText, MenuItem, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Select from '@mui/material/Select'
import { MESSAGE_TYPES } from 'constants/common'
import { CheckboxIcon } from 'icons/checkbox'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { storeActions } from 'redux/slices/storeSlice'
import { createMessage } from 'utils/common'

function SelectStore(props) {
  const dispatch = useDispatch()
  const userStores = useSelector((state) => state.store.stores)
  const selectedStore = useSelector((state) => state.store.selectedStore)
  const userInfo = useSelector((state) => state.user.user)
  const [data, setData] = useState(selectedStore)
  let listRole = [1, 773]
  const handleChange = (e) => {
    if (!e.target.value.includes("all")) {
      const {
        target: { value },
      } = e

      setData(typeof value === 'string' ? value.split(',') : value)
    }

  }
  // // Lưu dữ liệu vào localStorage
  const obj = JSON.parse(localStorage.getItem('stores'))
  localStorage.setItem('stores', JSON.stringify({ ...obj, arr: data }));

  const handleChangeItem = (e) => {
    setData([])
    dispatch(storeActions.userSelectedStore([]))
  }

  const renderValue = (selected) => {
    if (!listRole.includes(userInfo?.role?.level) && selected.length === 0) {
      return 'Chọn vị trí'
    }
    if (selected.length > 0) {
      return `Đã chọn ${selected.length} vị trí`
    } else return 'Tất cả'
  }

  const MenuProps = {
    PaperProps: {
      elevation: 0,
      sx: {
        '& span': {
          fontFamily: 'Be Vietnam Pro, sans-serif',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px'
        },
        borderRadius: '8px',
        minWidth: '200px',
        // overflow: 'visible',
        filter: 'drop-shadow(0px 0px 30px rgba(51, 51, 51, 0.2))',
        mt: '5px',
        maxHeight: '380px',
        overflow: 'auto',
        '& .MuiAvatar-root': {
          width: 32,
          height: 32,
          ml: -0.5,
          mr: 1,
        },
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 14,
          width: 10,
          height: 10,
          bgcolor: 'background.paper',
          transform: 'translateY(-50%) rotate(45deg)',
          zIndex: 0,
        },
        '& ul': {
          padding: 0
        }
      },

    },
    anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
  }

  useEffect(() => {
    dispatch(storeActions.userSelectedStore(data))
    // if (!listRole.includes(userInfo?.role?.level) && data.length === 0) {
    //   dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'Vui lòng chọn vị trí' }))
    // }
  }, [data])



  return (
    <div className="w-1/2 flex flex-row lg:gap-x-2 items-center justify-start lg:mr-4" style={{

    }}>
      <Typography
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
          mr: '12px',
          color: '#525252'
        })}
      >
        Chọn vị trí
      </Typography>
      <Select
        value={data || []}
        displayEmpty
        defaultValue=""
        multiple={true}
        onChange={handleChange}
        renderValue={renderValue}
        MenuProps={MenuProps}
        sx={{
          height: '40px',
          minWidth: '196px',
          border: '1px solid #EEEEEE',
          borderRadius: '6px',
          '& fieldset': {
            border: 'none'
          },
          color: '#333333',
        }}
      >
        {listRole.includes(userInfo?.role?.level) && (
          <MenuItem sx={{
            height: '48px',
            p: '14px 20px',
            '& span:first-child': {
              padding: 0,
              pr: '8px'
            }

          }} value={"all"} onClick={handleChangeItem}>
            <Checkbox checked={data.length <= 0} icon={<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="21" height="21" rx="3.5" fill="white" stroke="#CCCCCC" />
            </svg>

            } checkedIcon={<CheckboxIcon />} />
            <ListItemText primary="Tất cả" />
          </MenuItem>
        )}
        {userStores &&
          userStores.map((item, index) => {
            return (
              <MenuItem sx={{
                height: '48px',
                p: '14px 20px',
                '& span:first-child': {
                  padding: 0,
                  pr: '8px'
                }
              }}
                key={index.toString()} value={item.id}>
                <Checkbox checked={data.indexOf(item.id) > -1} icon={<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="21" height="21" rx="3.5" fill="white" stroke="#CCCCCC" />
                </svg>

                } checkedIcon={<CheckboxIcon />} />
                <ListItemText primary={item.name} />
              </MenuItem>
            )
          })}
      </Select>
    </div>
  )
}

export default SelectStore
