import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'

// 3th package
import { Formik } from 'formik'
import * as Yup from 'yup'

// Services
import * as logisticService from 'services/logistic.service'
import * as storeService from 'services/store.service'

import { styled } from '@mui/material/styles'
import { MESSAGE_TYPES } from 'constants/common'
import { useDispatch } from 'react-redux'
import { createMessage } from 'utils/common'


import MyTextField from 'components/MainContent/components/TextField'
import FormBox from 'components/ui/UiDialog/FormBox'
import _ from 'lodash'

DialogUserEditForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onUpdated: PropTypes.func,
}

DialogUserEditForm.defaultProps = {
  id: null,
  onUpdated: () => { },
}
const StyledDialog = styled('div')(() => {
  return {
    '& p, & h5': {
      fontFamily: 'Be Vietnam Pro, sans-serif',
      fontStyle: 'normal',
      color: '#333333',
    },
    '& .title': {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'center',
    },
    '& .avatar': {
      textAlign: 'center',
      backgroundImage: 'url("/bgAvatar.svg")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      padding: '14px 0',
      borderRadius: '8px',
      backgroundColor: '#DB1B33',
    },
    '& .form-control': {
      marginBottom: 0,
    },
    '& .title-input': {
      marginBottom: '4px',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#525252',
    },
  }
})

function DialogUserEditForm({ onUpdated, id, open, handleClose, key, dataDrawer }) {
  const [loading, setLoading] = useState(true)

  const [stores, setStores] = useState([])
  const [data, setData] = useState({})
  const [roles, setRoles] = useState([])
  const dispatch = useDispatch()

  const handleSave = async (values, { setSubmitting }) => {
    setSubmitting(true)
    try {
      await logisticService.adminUpdateUser(data.id, {
        categories: (values.categories || []).map((item) => item.id),
        stores: (values.stores || []).map((item) => item.id),
        role: values.role?.id
      })

      dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Cập nhật thông tin nhân viên thành công' }))
      onUpdated()
      setSubmitting(false)
    } catch (error) {
      dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'Cập nhật thông tin nhân viên không thành công' }))
      setSubmitting(false)
    }
  }


  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const res = await logisticService.getRole()
        setRoles(res)
      } catch (error) { }
    }

    const fetchUser = async () => {
      setData(dataDrawer)
      setLoading(false)
    }

    const fetchAllStores = async () => {
      try {
        const res = await storeService.getList()
        setStores(res?.data)

      } catch (error) {
      }
    }

    fetchRoles()
    fetchUser()
    fetchAllStores()
  }, [])

  const handleSearchStores = _.debounce(async (e) => {
    try {
      const res = await storeService.getList({ name: e })
      setStores(res?.data)
    } catch (error) {
    }
  }, 500)

  if (loading)
    return (
      <Container sx={{ p: 0, width: '100%' }}>
        <Stack alignItems="center" sx={{ p: 1 }}>
          <CircularProgress size={30} />
        </Stack>
      </Container>
    )

  return (
    <FormBox
      title="Chỉnh sửa thông tin nhân viên"
      open={open}
      onClose={handleClose}
      key={key}
      content={
        <>
          <Container sx={{ p: 0 }}>
            <Box className="avatar">
              <Avatar alt={data.name} src={data?.avatar} sx={{ width: 100, height: 100, margin: 'auto' }} />

              <p
                className="font-bold text-center"
                style={{
                  fontSize: '22px',
                  lineHeight: '32px',
                  color: '#FFFFFF',
                  marginTop: '16px',
                  marginBottom: '8px',
                }}
              >
                {data?.name}
              </p>
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#FFFFFF !important',
                  opacity: '0.8',
                }}
              >
                Mã nhân viên: <b>{data.username}</b>
              </Typography>
            </Box>
            <Formik
              initialValues={{
                name: data?.name,
                stores: (data.stores || []).map((item) => ({ id: item.id, name: item.name, selected: true })),
                role: data?.role,
              }}
              validationSchema={Yup.object().shape({
                // name: Yup.string().min(5, 'Tên phải lớn hơn 5 ký tự').required('Tên không được để trống'),
                // categories: Yup.array().nullable().min(1, 'Vui lòng chọn danh mục').required('Vui lòng vị chọn danh mục'),
                stores: Yup.array().min(1, 'Vui lòng chọn vị trí').required('Vui lòng chọn vị trí'),
                role: Yup.object().nullable()
              })}
              onSubmit={(values, { setSubmitting }) => {
                console.log(1);
                handleSave(values, { setSubmitting })
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
                return (
                  <form noValidate onSubmit={handleSubmit}>
                    <Box sx={{ minWidth: '800px' }}>
                      <FormControl variant="standard" fullWidth margin="normal" className="form-control">
                        <Typography className="title-input">Chức vụ</Typography>
                        <MyTextField
                          handleChange={handleChange}
                          placeholder="Nhập chức vụ"
                          error={Boolean(touched.position && errors.position)}
                          onBlur={handleBlur}
                          value={data.position}
                          //disabled={true}
                          readonly="readonly"
                        />
                        {errors.position && touched.position && (
                          <FormHelperText error>{errors.position}</FormHelperText>
                        )}
                      </FormControl>
                      <Grid container spacing={3}>
                        <Grid item md={6}>
                          <FormControl variant="standard" fullWidth margin="normal" className="form-control">
                            <Typography className="title-input">Số điện thoại</Typography>
                            <MyTextField
                              handleChange={handleChange}
                              error={Boolean(touched.phone && errors.phone)}
                              onBlur={handleBlur}
                              value={data.phone}
                              //disabled={true}
                              readonly="readonly"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6}>
                          <FormControl variant="standard" fullWidth margin="normal" className="form-control">
                            <Typography className="title-input">Ngày sinh</Typography>
                            <MyTextField
                              value={data.bod}
                              //disabled={true}
                              readonly="readonly"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <FormControl variant="standard" fullWidth margin="normal" className="form-control">
                        <Typography className="title-input">Phòng ban</Typography>
                        <MyTextField
                          value={data.company}
                          //disabled={true}
                          readonly="readonly"
                        />
                      </FormControl>

                      <FormControl variant="standard" fullWidth margin="normal" className="form-control">
                        <Typography className="title-input">Gmail</Typography>
                        <MyTextField
                          handleChange={handleChange}
                          error={Boolean(touched.email && errors.email)}
                          onBlur={handleBlur}
                          value={data.email}
                          //disabled={true}
                          readonly="readonly"
                        />
                      </FormControl>
                      <Grid container spacing={3}>
                        <Grid item md={6}>
                          <FormControl variant="standard" fullWidth margin="normal" className="form-control">
                            <Typography className="title-input">Telegram</Typography>
                            <MyTextField
                              handleChange={handleChange}
                              error={Boolean(touched.telegram && errors.telegram)}
                              onBlur={handleBlur}
                              value={data.telegram || data.phone}
                              //disabled={true}
                              readonly="readonly"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6}>
                          <FormControl variant="standard" fullWidth margin="normal" className="form-control">
                            <Typography className="title-input">Zalo</Typography>
                            <MyTextField
                              value={data.zalo || data.phone}
                              //disabled={true}
                              readonly="readonly"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <FormControl variant="standard" fullWidth margin="normal" className="form-control">
                        <Typography className="title-input">
                          Vị trí{' '}
                          <Typography variant="span" color="error">
                            (*)
                          </Typography>
                        </Typography>
                        <Autocomplete
                          size="medium"
                          sx={{
                            minHeight: '50px', '& span': {
                              fontFamily: 'Be Vietnam Pro, sans-serif',
                              fontStyle: 'normal',
                              fontSize: '14px',
                              fontWeight: '500',
                              lineHeight: '20px',
                              color: '#737373',
                            },
                            '& .MuiChip-root': {
                              height: '24px',
                              p: '2px 12px',
                              backgroundColor: '#F5F5F5',
                              borderRadius: '4px',
                            },
                            '& fieldset': {
                              height: '100%'
                            }
                          }}
                          name="stores"
                          multiple
                          disableCloseOnSelect
                          value={values.stores}
                          options={stores?.map((item) => ({
                            id: item.id,
                            name: item.name,
                          }))}
                          filterSelectedOptions={true}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          disablePortal
                          getOptionLabel={(option) => option.name}
                          filterOptions={createFilterOptions({
                            matchFrom: 'any',
                            stringify: (option) => option.name,
                          })}
                          renderOption={(props, option, { selected }) => {
                            return <li {...props}>{option.name}</li>
                          }}
                          renderInput={(params) => <TextField onChange={(e) => handleSearchStores(e.target.value)} {...params} />}
                          onChange={(e, val) => {
                            console.log(377, val);
                            setFieldValue('stores', val)
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                key={option.id + index}
                                label={option.name}
                                {...getTagProps({ index })}
                                deleteIcon={<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M1.34732 0.919371C1.15405 0.726095 0.833493 0.726095 0.640217 0.919371C0.446941 1.11265 0.446941 1.4332 0.640217 1.62648L4.0579 5.04416L0.640217 8.46184C0.446941 8.65512 0.446941 8.97567 0.640217 9.16895C0.833493 9.36222 1.15405 9.36222 1.34732 9.16895L4.76501 5.75127L8.18269 9.16895C8.37596 9.36223 8.69652 9.36222 8.8898 9.16895C9.08307 8.97567 9.08307 8.65512 8.8898 8.46184L5.47211 5.04416L8.8898 1.62648C9.08307 1.4332 9.08307 1.11265 8.8898 0.919371C8.69652 0.726095 8.37596 0.726095 8.18269 0.919371L4.76501 4.33705L1.34732 0.919371Z" fill="#737373" />
                                </svg>
                                }
                              />
                            ))
                          }
                        />
                        {errors.stores && touched.stores && <FormHelperText error>{errors.stores}</FormHelperText>}
                      </FormControl>

                      <FormControl fullWidth margin="normal" className="form-control">
                        <Typography className="title-input">Vai trò</Typography>
                        <Autocomplete
                          size="medium"
                          sx={{
                            minHeight: '50px', '& span': {
                              fontFamily: 'Be Vietnam Pro, sans-serif',
                              fontStyle: 'normal',
                              fontSize: '14px',
                              fontWeight: '500',
                              lineHeight: '20px',
                              color: '#737373',
                            },
                            '& .MuiChip-root': {
                              height: '24px',
                              p: '2px 12px',
                              backgroundColor: '#F5F5F5',
                              borderRadius: '4px',
                            },
                            '& fieldset': {
                              height: '100%'
                            }
                          }}
                          name="role"

                          disableCloseOnSelect
                          value={values.role}
                          options={roles?.map((item) => ({
                            id: item.id,
                            name: item.name,
                          }))}
                          filterSelectedOptions={true}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          disablePortal
                          getOptionLabel={(option) => option.name}
                          filterOptions={createFilterOptions({
                            matchFrom: 'any',
                            stringify: (option) => option.name,
                          })}
                          renderOption={(props, option, { selected }) => {
                            return <li {...props}>{option.name}</li>
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(e, val) => {
                            console.log(377, val);
                            setFieldValue('role', val)
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'flex-end', textTransform: 'none'
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disableElevation
                        disabled={isSubmitting}
                        sx={{
                          backgroundColor: '#2560E5',
                          borderRadius: '4px',
                          border: '1px solid #2560E5',
                          color: '#FFFFFF',
                          padding: '7px 12px',
                          fontSize: '16px',
                          lineHeight: '24px',
                          fontWeight: '400',
                          textTransform: 'none',
                          '&:hover': {

                            backgroundColor: '#2560E5',
                          }
                        }}
                      >
                        Lưu thông tin cá nhân
                      </Button>
                    </Box>
                  </form>
                )
              }}
            </Formik>
          </Container>
        </>
      }
    />
  )
}

export default DialogUserEditForm
